export default {

  sideMenu: {
    primary: [
      {
        name: "Proyectos",
        slug: "/proyectos/"
      },
      {
        name: "Servicios",
        slug: "/servicios/"
      },
      {
        name: "Binarios",
        slug: "/binarios/"
      },
      {
        name: "Contacto",
        slug: "/contacto/"
      },
      {
        name: "Blog",
        slug: "/blog/"
      }
    ],
    home: "/",
    languages: ["Español", "Català", "English"]
  },

  home: {
    helmet: {
      title: "Desarrollo y Diseño Web | Binaria, agencia digital",
      meta: "Binaria es una agencia digital de Barcelona con 20 años de trayectoria. Hacemos diseño UI/UX, sitios web, progressive web apps y otros productos digitales."
    },
    heroText: "Producto digital conectando técnica y creatividad",
    intro: "Somos una agencia digital con sede en Barcelona. Unimos nuestra pasión por el diseño centrado en las personas con tecnologías avanzadas de desarrollo.",
    projects: {
      title: "Nuestro trabajo",
      description: "Descubre parte de nuestros proyectos",
      link: "Todos los proyectos"
    },
    servicios: {
      title: "Qué hacemos",
      link: "Servicios"
    },
    clients: {
      title: "Nuestros clientes",
      link: "Conócenos"
    },
    contactHome: {
      title: "Escríbenos cuando quieras",
      description: "Estamos encantados de oír lo que quieras contarnos y ofrecerte las soluciones que necesita tu empresa."
    }
  },

  projects: {
    helmet: {
      title: "Proyectos | Binaria, agencia digital",
      meta: "Te mostramos algunos de los proyectos en los que hemos acompañado a nuestros clientes. Conoce ejemplos de las iniciativas digitales con las que trabajamos."
    },
    title: "Nuestro trabajo",
    description: "Descubre nuestros proyectos, experiencias digitales fáciles de usar, ágiles y fiables",
    navProjects: {
      left: "Anterior",
      center: "Ver todos",
      right: "Siguiente"
    }
  },

  services: {
    helmet: {
      title: "Servicios | Binaria, agencia digital",
      meta: "Descubre todos los servicios de Binaria. Nos especializamos en desarrollo Web y diseño UX/UI, entre otros."
    },
    heroTitle: "Déjanos las llaves de tu nave, nosotros pilotamos",
    servicesListTitle: "Qué hacemos",
    sliderTitle: "Cómo lo hacemos",
    carousel: {
      title: "¿Por qué nos apasiona?",
      subTitle: "Porque creemos que un buen producto...",
      sentences: {
        allSentencesWithHighlights: [
          "...es <span class='primary'>abierto</span> y <span class='secondary'>colaborativo</span>",
          "...requiere <span class='primary'>tiempo</span> y <span class='secondary'>constancia</span>",
          "...se trabaja cada día <span class='primary'>aprendiendo</span> e <span class='secondary'>investigando</span>",
          "...sale de mentes <span class='primary'>inquietas</span> y <span class='secondary'>humildes</span>",
          "...es <span class='primary'>innovador</span> y <span class='secondary'>carismático</span>",
          "...satisface las <span class='primary'>necesidades</span> y <span class='secondary'>expectativas</span> del usuario",
          "...en definitiva, es la <span class='primary'>sinergia</span> entre dos <span class='secondary'>cualidades</span>",
        ],
        allSentences: [
          "...es abierto y colaborativo",
          "...requiere tiempo y constancia",
          "...se trabaja cada día aprendiendo e investigando",
          "...sale de mentes inquietas y humildes",
          "...es innovador y carismático",
          "...satisface las necesidades y expectativas del usuario",
          "...en definitiva, es la sinergia entre dos cualidades",
        ]
      }
    }
  },

  binarios: {
    helmet: {
      title: "Nosotros | Binaria, agencia digital",
      meta: "Te contamos cómo y por qué hacemos lo que nos apasiona. Nosotros somos el equipo de Binaria."
    },
    heroTitle: "Somos dos fuerzas que crean una solución",
    whys: {
      title: "Por qué Binaria",
      description: "Somos un equipo entregado a cada proyecto de inicio a fin. Unimos <strong>creatividad</strong> y <strong>técnica</strong> para hacer experiencias digitales con un diseño de calidad y un desarrollo óptimo y eficiente. Especializados en usabilidad, arquitectura de la información y programación avanzada, usamos metodologías ágiles para asegurar la calidad de los proyectos y del equipo."
    },
    teamTitle: "Equipo",
    clients: {
      title: "Nuestros clientes"
    },
  },

  contact: {
    helmet: {
      title: "Contacto | Binaria, agencia digital",
      meta: "Contacta con Binaria, estamos encantados de conocerte y poder colaborar contigo! Completa el formulario y nos comunicaremos a la brevedad"
    },
    title: "Dispara ",
    description: "Somos una agencia digital especializada en diseño de producto y desarrollo avanzado. Estamos encantados de oír lo que quieras contarnos y ofrecerte las soluciones que necesita tu empresa.",
    phone: "Teléfono",
    email: "Email",
    address: "Dirección",
    address1: "Av Josep Tarradellas, 123, 6to piso",
    address2: "08029 Barcelona",
    address3: "Aticco",
    viewMap: "Ver mapa",
    placeholders: {
      name: "Nombre y apellidos",
      email: "Email",
      message: "Mensaje",
      accept: "Aceptación"
    },
    conditions: "Acepto recibir emails de Binaria y su",
    policyLink: "política de privacidad",
    policyPath: "/politica-de-privacidad",
    button: "Enviar",
    loadingMsg: "Enviando...",
    errorMsg: "Tu información no fue enviada. Por favor, inténtalo de nuevo.",
    successMsg: "Hemos recibido tu mensaje, ¡gracias!"
  },

  blog: {
    helmet: {
      title: "Blog | Binaria, agencia digital",
      meta: "Encuentra las últimas novedades en el mundo digital. Desde el equipo de Binaria, te contamos lo que hay que saber para estar al día con las tendencias."
    },
    title: "Blog ",
    subTitle: "Descubre nuestras reflexiones sobre diseño UI/UX y desarrollo"
  },

  post: {
    otherPostsTitle: "Otros artículos que pueden interesarte:"
  },

  contactBanner: {
    normal: {
      title: "Cuéntanos tu proyecto",
      button: "Contacta",
      link: "/contacto"
    },
    postBanner: {
      title: "¿Quieres saber más?",
      button: "Escríbenos",
      link: "/contacto"
    }
  },

  footer: {
    links: [
      {
        name: "Proyectos",
        slug: "/proyectos/"
      },
      {
        name: "Servicios",
        slug: "/servicios/"
      },
      {
        name: "Binarios",
        slug: "/binarios/"
      },
      {
        name: "Contacto",
        slug: "/contacto/"
      },
      {
        name: "Blog",
        slug: "/blog/"
      },
      {
        name: "Empleo",
        slug: "/empleo/"
      },
    ],
    legal: [
      "© 2019 Binaria Web Services S.L. Reservados todos los derechos. ",
      {
        name: "Aviso legal",
        path: "/aviso-legal/"
      },
      " y ",
      {
        name: "política de privacidad",
        path: "/politica-de-privacidad/"
      }
    ]
  },

  cookies: {
    description: "Esta web usa cookies para mejorar su experiencia. Al navegar en ella, entendemos que está de acuerdo con nuestra ",
    link: "política de privacidad",
    acceptButton: "Aceptar",
    rejectButton: "Rechazar",
    path: "/politica-de-privacidad/"
  },

  errorPage: {
    metaTitle: "Error 404 | Binaria, agencia digital",
    metaDesc: "Te contamos cómo y por qué hacemos lo que nos apasiona. Nosotros somos el equipo de Binaria.",
    title: "Error 404",
    text: "¡Lo sentimos! ¡La página que estás buscando no existe!",
    button: "Volver al inicio",
    link: "/"
  }

}